<template>
    <span>
        <div class="d-inline-block mr-5" v-if="show_points === 'true'">
            <v-icon class="lime--text">mdi-star-circle-outline</v-icon>
            {{post.points}}
        </div>
        <div class="d-inline-block" :key="unlock_voting_icons">
            <v-icon class="mr-5" :class="{'success--text':post.action === '+'}" @click.once="vote_post('+')">
                {{post.action === '+' ? 'mdi-thumb-up' : 'mdi-thumb-up-outline'}}
            </v-icon>

            <v-icon :class="{'ml-5':show_points !== 'true', 'error--text':post.action === '-'}" @click.once="vote_post('-')">
                {{post.action === '-' ? 'mdi-thumb-down' : 'mdi-thumb-down-outline'}}
            </v-icon>
        </div>
    </span>
</template>

<script>
    export default {
        props  : ["post", "show_points"],
        data() {
            return {
                unlock_voting_icons: 0,
            }
        },
        methods: {
            async vote_post(action) {
                if (this.$user.user.auth) {
                    if (action !== this.post.action) { // If action is not the same
                        let data     = {
                            action: action,
                            postid: this.post.ID,
                            userid: this.$user.user.id,
                            token : this.$user.user.token,
                        };
                        let response = await this.func.ax("/php/post_like.php", data);

                        await this.$root.check_response(response, async () => {
                            let response = await this.func.ax("/php/getter.php", {
                                func  : "get_post_points",
                                postid: this.post.ID,
                            });
                            this.$emit("voted", action, response.points);
                        }, "post.msg.error-vote");
                    }
                }
                else
                    this.eventbus.$emit("SB", "post-pages.msg.login-to-rate", "warning", 5);
                this.unlock_voting_icons++;
            },
        },
    }
</script>